<template>
  <div>
    <atoms-card
      size="none"
      shadow="xl"
      class="fixed z-50 w-full bottom-0 left-0 rounded-none lg:bottom-48 lg:rounded-tr-xl lg:rounded-br-xl lg:w-auto"
      :class="{ 'overflow-hidden': !showCallPopUp }"
    >
      <ul class="w-full flex items-center lg:block lg:w-26">
        <li class="w-1/3 lg:w-full text-center border-b border-b-gray-300 border-r border-r-gray-300 lg:border-r-0">
          <button
            id="cta-gsd-call"
            class="py-2 lg:px-3 lg:py-4"
            @click="click($event, 'modalSpeakWithUs')"
          >
            <NuxtImg
              src="/icons/phone-chat.svg"
              class="block mx-auto"
              width="32"
              height="32"
              alt="Call"
            />
            <p class="text-2xs font-bold lg:mt-2">
              Speak with us
            </p>
          </button>
        </li>
        <li class="w-1/3 lg:w-full text-center border-b border-b-gray-300 border-r border-r-gray-300 lg:border-r-0">
          <button
            id="cta-gsd-callback"
            class="px-3 py-2 lg:py-4"
            @click="click($event, 'modalScheduleCallback')"
          >
            <NuxtImg
              src="/icons/phone-schedule.svg"
              class="block mx-auto"
              width="32"
              height="32"
              alt="Callback"
            />
            <p class="text-2xs font-bold lg:mt-2">
              Request a callback
            </p>
          </button>
        </li>
        <li class="w-1/3 lg:w-full text-center bg-dark">
          <a
            id="cta-gsd-quote"
            class="px-3 py-2 lg:py-4 block text-white text-2xs"
            :href="verticals.formPage"
            @click="(e) => $tracking.click(e)"
          >
            <NuxtImg
              src="/icons/notes.svg"
              class="block mx-auto lg:mb-2"
              width="32"
              height="32"
              alt="Free quote"
            />
            GET YOUR FREE QUOTE
          </a>
        </li>
      </ul>
    </atoms-card>

    <!-- MODAL FOR VERTICAL GSD MODULE -->
    <client-only>
      <molecules-modal
        ref="modalSpeakWithUs"
        size="small"
        card-size="standard"
        class="text-dark"
      >
        <h4 class="text-lg font-bold mb-4">
          Speak with us
        </h4>
        <p class="mb-4">
          {{ verticals.speakWithUsText }}
        </p>
        <atoms-cta
          class="mb-4"
          size="small"
          :to="`tel:${verticals.phoneNumber}`"
        >
          CALL {{ verticals.phoneNumber }}
        </atoms-cta>
        <div class="text-center">
          <p class="text-sm font-bold mb-1">
            {{ verticals.schedule }}
          </p>
          <p
            v-if="verticals.schedule_weekend"
            class="text-sm font-bold mb-1"
          >
            {{ verticals.schedule_weekend }}
          </p>
          <atoms-cta
            theme="tertiary"
            size="small"
            @click="modalSpeakWithUs.toggle(false)"
          >
            CANCEL
          </atoms-cta>
        </div>
      </molecules-modal>

      <!-- MODAL FOR CALLBACK GSD MODULE -->
      <molecules-modal
        v-if="isNewCallback"
        ref="modalScheduleCallback"
        size="xLarge"
        class="text-dark"
        card-size="standard"
        :close-action="closeNewCallbackForm"
      >
        <molecules-schedule-callback
          ref="scheduleCallbackRef"
          class="py-1.5"
          :vertical="props.vertical"
          :prefill-data="userDetails"
          @close="closeNewCallbackForm"
        >
          <template #cancelButton>
            <atoms-cta
              id="gsd-request_call_back_form_close"
              theme="tertiary"
              size="medium"
              :full="true"
              type="button"
              class="underline uppercase"
              @click="closeNewCallbackForm"
            >
              Cancel
            </atoms-cta>
          </template>
        </molecules-schedule-callback>
      </molecules-modal>
      <molecules-modal
        v-else
        ref="modalScheduleCallback"
        :size="isLeadEnabledRequestCallback?'xLarge':'large'"
        card-size="standard"
        class="text-dark"
      >
        <molecules-schedule-callback-generic
          class="py-1.5"
          :vertical="props.vertical"
          :show-response="true"
          :prefill-data="userDetails"
          @close="closeCallbackForm"
        />
      </molecules-modal>
    </client-only>
    <!-- END OF MODAL FOR VERTICAL GSD MODULE -->
  </div>
</template>

<script setup>
const { $tracking } = useNuxtApp()

defineOptions({
  name: 'OrganismsGsdBar'
})

const props = defineProps({
  vertical: {
    type: String,
    default: 'Health Insurance'
  }
})

const modalSpeakWithUs = ref(false)
const modalScheduleCallback = ref(false)

const showCallPopUp = false

const scheduleCallbackRef = ref()

const verticals = computed(() => VERTICALS[props.vertical])

const userStore = useUserStore()

const userDetails = computed(() => {
  return {
    full_name: userStore.getUserProfile?.given_name && userStore.getUserProfile?.family_name ? userStore.getUserProfile?.given_name + ' ' + userStore.getUserProfile?.family_name : '',
    phone: userStore.isEmailVerified && userStore.getUserProfile?.phone_number ? userStore.getUserProfile?.phone_number : '',
    state: userStore.isEmailVerified && userStore.getUserProfile?.profile?.state ? userStore.getUserProfile?.profile?.state : '',
    user_email: userStore.getUserProfile?.email || ''
  }
})

const click = (event, type) => {
  switch (type) {
    case 'modalSpeakWithUs':
      modalSpeakWithUs.value.toggle(true)
      break
    case 'modalScheduleCallback':
      modalScheduleCallback.value.toggle(true)
      break
    default:
      break
  }
  /* if (type) {
    for (const ref in this.$refs) {
      if (ref !== type && ref.includes('modal')) {
        this.$refs[ref].toggle(false)
      }
    }

    this.$refs[type].toggle()
    callbackSubmitted = false
  } */

  $tracking.click(event)
}

const closeCallbackForm = () => {
  modalScheduleCallback.value.toggle(false)
}

const closeNewCallbackForm = () => {
  scheduleCallbackRef.value.clearForm()
  modalScheduleCallback.value.toggle(false)
}

const isNewCallback = computed(() => {
  return [VERTICAL.HEALTH_INSURANCE].includes(props.vertical)
})

const isLeadEnabledRequestCallback = computed(() => {
  return [VERTICAL.ENERGY].includes(props.vertical)
})

</script>
